function videoPlayButtonHandler() {
    const playButtons = document.querySelectorAll('button.cblvc-video-container__trigger-button');
    Array.from(playButtons).forEach(button => {
        button.addEventListener('click', function(event) {
            const videoElmnt = document.querySelector('div[data-videoid="'+ button.dataset.triggerid +'"]');

            if(videoElmnt){
                const ytIframeElmnt = videoElmnt.querySelector('iframe');
                const videoPlatform = videoElmnt.dataset.videotype;
                const videoTitle = ytIframeElmnt.title;
                const videoURL = ytIframeElmnt.dataset.src;

                if(videoPlatform != '') {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event':'interaction',
                        'eventCategory':'video',
                        'eventAction':videoPlatform + '|'+ videoTitle +'|'+videoURL,
                        'eventLabel':'start'
                    });
                }
            }
            event.preventDefault();
        });
    });
}

export { videoPlayButtonHandler };