window.isCTBLoaded = false;

async function loadScript() {
  const script = document.createElement('script');
  script.src = '/wp-content/plugins/pr-clicktobuy/public/js/pr-clicktobuy-public.js';
  script.async = true;
  document.head.appendChild(script);
  await new Promise((resolve) => {
    script.onload = resolve;
  });
}

async function handleClick(event) {
  event.preventDefault();

  if (window.isCTBLoaded) {
    return;
  }

  await loadScript();
  const clonedEvent = new event.constructor(event.type, event);
  setTimeout(() => {
    event.target.dispatchEvent(clonedEvent);
  }, 500);
  window.isCTBLoaded = true;
}

function ctbCustomMenuClickHandler() {
  document.querySelectorAll('[data-ctbuy]').forEach(el => {
    el.addEventListener('click', handleClick);
  });
}

export {ctbCustomMenuClickHandler};
