export default function preTickCdbOptIn() {
  function fetchData() {
    fetch('https://avp.pravp.com/whereami')
      .then((response) => response.json())
      .then((data) => {
        if (data.countryCode === 'us') {
          const checkbox = document.querySelector(
            '.cdb_form_container .cdb_form_field.optin input[type="checkbox"]'
          );
          if (checkbox) {
            checkbox.checked = true;
          }
        }
      })
      .catch((error) => console.error('Error:', error));
  }

  if (document.readyState !== 'complete') {
    document.addEventListener('DOMContentLoaded', fetchData);
  } else {
    fetchData();
  }
}
